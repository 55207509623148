<ng-container *ngIf="details() ?? programDetails() as program">
	<div class="program-container">
		<div *ngIf="program?.decline_reason!==null&&program.status==2"
			class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
			{{"decline_reason_program"|translate}}
			{{program?.decline_reason}}
		</div>
		<h3 *ngIf="program?.decline_reason==null&&program.status==2"
			class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
			{{"decline_reason_program_null"|translate}}
			{{program?.decline_reason}}
		</h3>
		<div class="grid grid-cols-12 gap-2 bg-semiDarkBlue p-2 rounded-2xl place-items-center">

			<div class="col-span-12  flex items-center justify-center w-full my-3">
				<hr class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700" />

				<span
					class="program-name text-semiDarkBlue capitalize font-extrabold text-2xl md:text-3xl mx-4 bg-amber-400">
					{{
					translateService.currentLang == 'en' ? program.name_en : program.name_ar

					}}
				</span>
				<hr class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700" />

			</div>

			<!-- Details -->
			<div class="col-span-12 text-center">

				<!-- Highlight -->
				<div class="flex gap-2 flex-wrap justify-center items-center my-3">

					<!-- Price -->
					<div *ngIf="!!program.price || !!program.total_amount"
						class="flex gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl">
						<icon [name]="ICONS.cost" iconClass="w-[1.5rem] h-[1.5rem]"></icon>
						<div class="flex flex-col">
							<small class="text-textYellow">{{
								"account.academy_programs_schedule.price"
								| translate
								}}
							</small>
							<p class="text-fontColor text-base break-words">
								<strong class="text-white pr-1">{{
									program.total_amount | number: '2.0-1'
									}}</strong>
								<small>{{
									"SPORT_ACADEMIES.Currency_eg" | translate
									}}</small>

								@if (!!+program.discount) {
							<p class="diagonal-strike mx-2 text-textYellow">{{
								program.price | number: '2.0-1'
								}}</p>
							}
							</p>
						</div>
					</div>

					<!-- start_date -->
					<ng-container *ngTemplateOutlet="
							highlightData;
							context: {
								icon: ICONS.timer,
								label: 'account.academy_programs_schedule.start_date',
								value: program?.start_date
							}
						">
					</ng-container>

					<!-- <ng-container
									*ngTemplateOutlet="
										highlightData;
										context: {
											icon: ICONS.calender,
											label: 'booking.schedule',
											value: program?.program_start_times
										}
									"
								>
								</ng-container> -->
					<!-- schedule -->
					<div *ngIf="!!program.week_days?.length || !!program.program_start_times?.length"
						class="flex gap-2 items-center justify-center bg-darkBlue p-2 rounded-2xl  overflow-hidden">
						<icon [name]="ICONS.calender" iconClass="w-[1.5rem] h-[1.5rem]"></icon>
						<div class="flex flex-col">

							<small class="text-textYellow">
								{{ "booking.schedule" | translate }}
							</small>
							<p *ngIf="program?.program_start_times"
								class="text-fontColor text-base break-words w-full text-center" dir="ltr">
								{{ program.program_start_times | timeFormat }}
							</p>

							<ul *ngIf="!!program.week_days?.length"
								class="text-fontColor text-base flex gap-2 flex-wrap justify-center w-full">
								<ng-container *ngFor="let day of program.week_days">
									<li class="break-words">
										{{ day | weekDay | translate }}
									</li>
								</ng-container>
							</ul>

							<ng-container *ngIf="!!program.program_start_times?.length">
								<ng-container *ngFor="let time of program.program_start_times">
									<span class="text-white block text-center w-full break-words">{{
										time | timeFormat
										}}</span>
								</ng-container>
							</ng-container>
						</div>
					</div>


				</div>

				<div class="flex flex-wrap">
					<!-- Description -->
					<div class="w-full break-all whitespace-normal">
						<ng-container *ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-circle-on',
									label: 'account.branches_management.add_branch.program_description',
									value:
										translateService.currentLang == 'en'
											? program.description_en
											: program.description_ar
								}
							">
						</ng-container>
					</div>

					<!-- Program Duration -->
					<div class="w-1/2">
						<ng-container *ngTemplateOutlet="
										iconItem;
										context: {
											primeIcon: 'pi-calendar',
											label: 'account.academy_programs_schedule.program_duration',
											value: program.duration,
											valueLabel:  program.duration ===1 ?'booking.timeDuration.week':'booking.timeDuration.weeks'
										}
									">
						</ng-container>
					</div>

					<!-- number_of_sessions -->
					<div class="w-1/2">
						<ng-container *ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-ticket',
									label: 'account.academy_programs_schedule.no_of_week_training',
									value: program?.number_of_sessions
								}
							">
						</ng-container>
					</div>

					<!-- session_duration -->
					<div class="w-1/2">
						<ng-container *ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-stopwatch',
									label: 'session_duration',
									value:
										program?.duration_session! / 60
										| number : '1.00',
									valueLabel: 'hour'
								}
							">
						</ng-container>
					</div>

					<!-- max_number_of_players -->
					<div class="w-1/2">
						<ng-container *ngTemplateOutlet="
											iconItem;
											context: {
												primeIcon: 'pi-hashtag',
												label: 'account.academy_programs_schedule.max_no_of_players',
												value: program?.max_number_of_players,
											}
										">
						</ng-container>
					</div>

					<!-- Branch name -->
					<div class="w-1/2">
						<ng-container *ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-warehouse',
									label: 'account.account_management.account_info.bank_branch_name',
									value: program?.branch?.branch_name
								}
							">
						</ng-container>
					</div>

					<!-- address -->
					<div class="w-1/2">
						<ng-container *ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-map-marker',
									label: 'account.account_management.account_info.address',
									value: program?.branch?.address
								}
							">
						</ng-container>
					</div>

					<ng-container *ngIf="isUserLogin$ | async">
						<!-- Contact Information -->
						<div *ngIf="isUserLogin$ | async" class="flex items-center justify-center w-full my-6">
							<hr class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700" />
							<span class="mx-3 font-medium text-textYellow">{{
								"contactInformation" | translate
								}}</span>
							<hr class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700" />
						</div>

						<!-- contact name -->
						<div class="text-sm text-fontColor  my-1 text-center w-3/4 mx-auto">
							{{"contact.customerService"|translate}} <a href="tel:01101003140"
								class="tel">01101003140</a>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #highlightData let-icon="icon" let-label="label" let-value="value" let-valueLabel="valueLabel">
	<div *ngIf="value" class="flex gap-2  items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl">
		<icon [name]="icon" iconClass="w-[1.5rem] h-[1.5rem]"></icon>
		<div class="flex flex-col">
			<small class="text-textYellow">{{ label | translate }} </small>
			<p class="text-fontColor text-base break-words whitespace-normal">
				<strong class="text-white">{{ value }}</strong>
				<small>{{ valueLabel | translate }}</small>
			</p>
		</div>
	</div>
</ng-template>

<ng-template #itemData let-label="label" let-subLabel="subLabel" let-value="value" let-valueLabel="valueLabel">
	<div *ngIf="value" class="flex flex-col items-start gap-4 bg-semiDarkBlue p-4 rounded-2xl">
		<div class="flex flex-col w-full justify-between gap-2 ">
			<small class="text-textYellow ">
				{{ label | translate }}
			</small>
			<small *ngIf="subLabel" class="text-semiDarkGrey break-words">
				{{ subLabel | translate }}
			</small>
			<p class="text-fontColor text-base break-words">
				{{ value }}
				<small>{{ valueLabel | translate }}</small>
			</p>
		</div>
	</div>
</ng-template>

<ng-template #iconItem let-primeIcon="primeIcon" let-label="label" let-value="value" let-valueLabel="valueLabel">
	<span *ngIf="value" class="flex  items-start gap-2 mb-1">
		<!-- Icon -->
		<i *ngIf="primeIcon" class="pi text-textYellow" style="font-size: 15px"
			[ngClass]="primeIcon ? primeIcon : ''"></i>
		<div class="flex flex-col  justify-start items-start  gap-2">
			<small style="font-size: 12px" class="text-textYellow  text-start">{{ label | translate }}
			</small>
			<p class="text-fontColor  text-base  whitespace-normal">
				<small class="text-white   text-start">{{ value }}</small>
				<small class="px-1">{{ valueLabel | translate }}</small>
			</p>
		</div>
	</span>
</ng-template>