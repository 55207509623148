import {
	AfterViewInit,
	Component,
	inject,
	Input,
	OnInit,
	signal,
	ViewChild,
	ViewEncapsulation,
	WritableSignal,
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from "@angular/forms";
import { ProgramsScheduleService } from "@sportyano/core/services/academy/programs-schedule/programs.schedule.service";
import { UserType } from "@sportyano/core/models/user-type/user-type";
import { Profile } from "@sportyano/core/models/account/account";
import {
	map,
	Observable,
	Subject,
	switchMap,
	takeUntil,
	takeWhile,
	tap,
	timer,
} from "rxjs";
import {
	RegisterResponse,
	UserRegisterationCycle,
} from "@sportyano/core/models/authentications/register-data.model";
import { PlayerService } from "@sportyano/core/services/playerProfile/player.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
	CustomValidationType,
	CustomValidators,
	VariablesValidation,
} from "@sportyano/shared/directives/custom-validation-reactiveForm";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { IProgramDetails } from "@sportyano/core/models/programs/program-details";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { permission } from "@sportyano/core/models/permissions/permission";
import { Otp } from "@sportyano/core/models/authentications/otp-model";
import { NgOtpInputModule } from "ng-otp-input";
import { CommonModule } from "@angular/common";
import { TabViewModule } from "primeng/tabview";
import { CheckLanguageDirective } from "@sportyano/shared/directives/check-language/check-language.directive";
import { programDetailsQuickComponent } from "@sportyano/main-site/academies/program-details-quick/program-details-quick.component";
import {
	RECAPTCHA_V3_SITE_KEY,
	RecaptchaV3Module,
	ReCaptchaV3Service,
} from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { MatButtonModule } from "@angular/material/button";
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef,
} from "@angular/material/dialog";
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { HeaderComponent } from "@sportyano/core/header/header.component";
import { ANIMATIONS } from "@sportyano/shared/animation/animation";
import { AnimationOptions, LottieComponent } from "ngx-lottie";
import { IconComponent } from "@sportyano/shared/components/icon/icon.component";
import { ICONS } from "@sportyano/shared/components/icon/models/icon";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { PAYMENT_METHOD } from "@sportyano/main-site/booking/booking/models/const/booking.const";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingBarModule } from "@ngx-loading-bar/core";

@Component({
	selector: "app-quick-register",
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TabViewModule,
		TranslateModule,
		NgOtpInputModule,
		CheckLanguageDirective,
		programDetailsQuickComponent,
		RecaptchaV3Module,
		MatButtonModule,
		MatDialogModule,
		MatButtonModule,
		MatStepperModule,
		HeaderComponent,
		LottieComponent,
		IconComponent,
		NgxSpinnerModule,
		LoadingBarModule,
	],
	providers: [
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha_site_key,
		},
	],
	templateUrl: "./quick-register.component.html",
	styleUrl: "./quick-register.component.scss",
	encapsulation: ViewEncapsulation.None,
	animations: ANIMATIONS,
})
export class QuickRegisterComponent implements OnInit, AfterViewInit {
	// inject
	private _fb = inject(FormBuilder);
	private _programSchedule = inject(ProgramsScheduleService);
	breakpointObserver = inject(BreakpointObserver);
	private _toast = inject(ToasterMessagesService);
	private _authService = inject(AuthService);
	public translateService = inject(TranslateService);
	private recaptchaV3Service = inject(ReCaptchaV3Service);
	public data = inject(MAT_DIALOG_DATA);
	private _dialogRef = inject(MatDialogRef<QuickRegisterComponent>);
	private services = inject(PlayerService);
	private activatedRoute = inject(ActivatedRoute);
	// Input
	@Input() programData: IProgramDetails = this.data?.programDetails;
	//ViewChild
	@ViewChild("stepper") stepper!: MatStepper; // Access the stepper using @ViewChild
	// Signals
	public resendOtpAttempts: WritableSignal<number> = signal<number>(0);
	public countdownSeconds: WritableSignal<number> = signal<number>(0);
	public countdownRedirect: WritableSignal<number> = signal<number>(0);
	public showOTPStep: WritableSignal<boolean> = signal(false);
	private _resendOTPToken: WritableSignal<string | null> = signal<
		string | null
	>(null);
	// Forms
	public playerDataInfo: FormGroup;
	public otpFormControl = new FormControl();
	public programForm: FormGroup;
	basicInfoForm: FormGroup; // Form group for Basic Info
	// Private
	private _playerId: number = 0;
	private _routeName: string;
	private _userType: UserType;
	private _isMobileScreen: boolean;
	private _password: string;

	// Public
	public ICONS = ICONS;
	public maximumOtpAttempts: number = 5;
	public CustomValidationType = CustomValidationType;

	public congratsLottie: AnimationOptions = {
		path: "/assets/json/success.json",
		loop: true,
	};
	private _countDownDestroy$: Subject<void> = new Subject<void>();
	public isDialogVisible$: Observable<boolean> =
		this._authService.getQuickLoginPopupState();
	private unsubscribe$: Subject<void> = new Subject<void>();

	ngOnInit(): void {
		this.initializeForm();
		this.checkScreenSize();
		this._password = this._generateRandomPassword();
		this.buildQuickRegisterForm();
	}

	ngAfterViewInit(): void {
		this._listenToLastStepForNavigation();
	}

	private _listenToLastStepForNavigation(): void {
		this.stepper.selectionChange.subscribe(
			(event: StepperSelectionEvent) => {
				if (event.selectedIndex === 3) {
					this.onDoneStepActivated();
				}
			}
		);
	}

	private _generateRandomPassword(): string {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?";
		const passwordLength = Math.floor(Math.random() * (15 - 6 + 1)) + 6; // Random length between 6 and 15
		this._password = "";

		// Generate the password
		for (let i = 0; i < passwordLength; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			this._password += characters[randomIndex];
		}

		return this._password;
	}
	private removeQueryParams(): void {
		const url = new URL(window.location.href);
		url.search = ''; // Remove all query parameters
		window.history.replaceState(null, '', url.toString());
	}

	private onDoneStepActivated() {
		this.countdownRedirect.set(30);
		timer(0, 1000)
			.pipe(
				takeUntil(this._countDownDestroy$),
				map(() => {
					return this.countdownRedirect();
				}),
				takeWhile((seconds) => seconds > 0)
			)
			.subscribe({
				next: () => {
					this.countdownRedirect.update((s) => s - 1);
					return this.countdownRedirect();
				},
				complete: () => {
					this.removeQueryParams();
					this.closeDialog();
					this._destroyCounter();
					window.location.reload();

				},
			});
	}

	buildQuickRegisterForm() {
		this.programForm = this._fb.group({
			username: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.egyptianMobileNumberValidation(),
				],
			],
			phone_number: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.egyptianMobileNumberValidation(),
				],
			],
			password: [
				this._password,
				[
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.password_minLength,
						VariablesValidation.password_maxLength
					),
				],
			],
			recaptcha: ["", Validators.required],
		});

		// To set the phone_number as the value of username:
		this.programForm.get("username")?.valueChanges.subscribe((value) => {
			this.programForm.get("phone_number")?.setValue(value);
		});
	}

	sendOtpToPhone() {
		this.recaptchaV3Service.execute("importantActionQuick").subscribe({
			next: (token: string) => {
				if (token) {
					console.log("reCAPTCHA token received:", token);

					// Set the received token to the recaptcha form control
					this.programForm.get("recaptcha")?.setValue(token);
					this.programForm.patchValue({ recaptcha: token });
					console.log("Updated form value:", this.programForm.value);

					// Proceed with submitting the form
					this._authService
						.signUp(this.programForm.value, "viewer")
						.subscribe({
							next: (res: RegisterResponse) => {
								this._authService.setRegisterSessionCycle(
									"createAccountToken",
									res.token
								);
								if (res.token) {
									this.showOTPStep.set(true);
									this._startCountdown();
									this._authService.setRegisterSessionCycle(
										"autoLoginData",
										res
									);
								}
							},
							error: (err) => {
								// Handle error from sign-up API
								console.error("Error during sign-up:", err);
							},
						});
				} else {
					// Handle case where no token was received
					console.error("reCAPTCHA token is empty or invalid");
				}
			},
			error: (err) => {
				// Handle error when reCAPTCHA execution fails
				console.error("Error executing reCAPTCHA:", err);
			},
		});
	}


	get email() {
		return this.playerDataInfo.get("email");
	}
	get player_id() {
		return this.playerDataInfo.get("player_id");
	}


	// ------------initializeForm and Valiations----------------------//
	initializeForm() {
		this.playerDataInfo = this._fb.group({
			first_name_ar: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.arabicOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			last_name_ar: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.arabicOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			first_name_en: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.englishOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			last_name_en: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.englishOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			email: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.emailPatternValidation(),
				],
			],
		});
	}
	async onSubmit() {
		switch (this._userType) {
			case permission.viwer: {
				this.services
					.createViewerProfileQuick(
						this._routeName,
						{
							name_ar:
								this.playerDataInfo.get("first_name_ar")
									?.value +
								" " +
								this.playerDataInfo.get("last_name_ar")?.value,
							name_en:
								this.playerDataInfo.get("first_name_en")
									?.value +
								" " +
								this.playerDataInfo.get("last_name_en")?.value,
							email: this.playerDataInfo.get("email")?.value,
						},
						this._playerId,
						this.access_token
					)
					.pipe(
						switchMap((t) => {
							return this._programSchedule.programBooking(
								String(this.programData.id),
								{
									payment_method: PAYMENT_METHOD.cash,
								}
							);
						})
					)
					.subscribe({
						next: (res) => {
							if (res.status == "success") {
								this.isRegisteredSuccess = true;
								this.goToNextTab();
							}
						},
						error: (err) => {
							console.log(err);
						},
					});
			}
		}
	}
	isProgramDetails: boolean = true;
	goToPhoneRegister() {
		this.goToNextTab();
		this.isProgramDetails == false;
	}

	checkScreenSize(): void {
		this.breakpointObserver
			.observe([
				Breakpoints.Handset, // Mobile devices
				Breakpoints.Tablet, // Tablet devices
				Breakpoints.Web, // Desktop devices
			])
			.subscribe((result) => {
				if (result.matches) {
					// Mobile or Tablet view
					this._isMobileScreen = true;
				} else {
					// Desktop view
					this._isMobileScreen = false;
				}
			});
	}

	public closeDialog() {
		this._dialogRef.close();
	}

	public resendOtp(): void {
		if (!this.countdownSeconds()) {
			this._sendOtp();
		}
	}
	private _sendOtp(): void {
		const userPhone =
			this._authService.getRegisterSessionCycle().autoLoginData.user
				.username;
		this._authService
			.resendOTP(userPhone)
			.pipe(
				tap((response) => {
					// Store  token
					this._resendOTPToken.set(response.token);
				})
			)
			.subscribe({
				next: (response) => {
					this._showSuccessToaster(response.message);
					this.resendOtpAttempts.set(response?.resend_attempts);
					this._startCountdown();
				},
				error: () => {
					this._resendOTPToken.set(null);
				},
			});
	}
	access_token: string = "";
	private handleCreateAccountVerification(dataToSend: any): void {
		this._authService
			.otpVerification(dataToSend)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (response) => {
					this._showSuccessToaster(
						"authentication.registeration_types.registeredSuccessfully"
					);
					if (response.status != "success") {
						this._toast.showError(
							"OTP verification failed. Please try again."
						);
					}

					this._authService.setToken = response.access_token;
					this.access_token = response.access_token;

					const autoLoginData =
						this._authService.getRegisterSessionCycle()
							.autoLoginData;

					this._authService.setAuthenticationData(autoLoginData);
					this._authService.setToken = response.access_token;

					this.goToNextTab();
				},
			});
	}

	submitOtp() {
		let dataToSend: Otp;
		if (this.otpFormControl.value.length !== 4) {
			this._toast.showError(
				this.translateService.instant(
					"authentication.login.form.otp_check"
				)
			);
			return;
		} else {
			dataToSend = {
				token: this._authService.getRegisterSessionCycle().autoLoginData
					.token,
				otp: this.otpFormControl.value,
			};
		}

		this._playerId =
			this._authService.getRegisterSessionCycle().autoLoginData.user.id;
		this._userType =
			this._authService.getRegisterSessionCycle().autoLoginData.userType;
		this._routeName = this._userType + "s";
		this.player_id?.patchValue(this._playerId);

		this.handleCreateAccountVerification(dataToSend);
	}
	private _destroyCounter(): void {
		this._countDownDestroy$.next();
		this._countDownDestroy$.complete();
	}
	private _startCountdown(): void {
		this.countdownSeconds.set(60);
		timer(0, 1000)
			.pipe(
				takeUntil(this._countDownDestroy$),
				map(() => {
					return this.countdownSeconds();
				}),
				takeWhile((seconds) => seconds > 0)
			)
			.subscribe({
				next: () => {
					this.countdownSeconds.update((s) => s - 1);
					return this.countdownSeconds();
				},
				complete: () => {
					this._destroyCounter();
				},
			});
	}

	private _showSuccessToaster(message: string): void {
		this._toast.showSuccess(this.translateService.instant(message));
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	isRegisteredSuccess: boolean = false;

	goToNextTab(): void {
		this.stepper.next();
	}
}
