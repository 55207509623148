import { CommonModule, isPlatformBrowser } from "@angular/common";
import { LanguagesService } from "../services/language services/languages.service";
import { Component, Inject, OnInit, PLATFORM_ID, inject } from "@angular/core";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { SideMenuItem } from "@sportyano/core/models/side-menu-items/SideMenuItem";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { ClickOutsideDirective } from "@sportyano/shared/components";
import { LanguageComponent } from "@sportyano/shared/components/language/language.component";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrl: "./header.component.scss",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		RouterLink,
		RouterLinkActive,
		ClickOutsideDirective,
		LanguageComponent,
	],
})
export class HeaderComponent implements OnInit {
	translationcurr: string = "";
	// uncomment when w enable Partial access to website feature
	// @Input("temporaryDisaplyMainPages") temporaryDisaplyMainPages:
	// 	| boolean
	// 	| null = false;
	private _authService = inject(AuthService);
	userType: UserType = this._authService.getUserType();
	isAuthenticated$ = this._authService.getAuthenticationState();
	currentLanguage: string;
	isMenuOpened: boolean = false;
	newNotificationAlert: boolean = true;
	notificationOpened: boolean;
	accountMenuOpened: boolean;
	notificationAndAccountContainer: HTMLElement | undefined;
	languageOpened: boolean = false;
	langStyles: object;
	langAnchorStyles: object;

	public paramsPage = {
		players: "player",
		academies: "academy",
		playgrounds: "playground",
		videos: "video",
	};

	accountManagementMenu: Array<SideMenuItem> = [
		{
			label: "header.account_items.account_management",
			route: "/main/account",
			permission: [
				permission.academy,
				permission.player,
				permission.viwer,
				permission.playground,
				permission.scouting,
			],
			isAdmin: true,
		},
		{
			label: "header.account_items.profile_management",
			route: "/main/account/profile-management",
			permission: [permission.player, permission.viwer],
			isAdmin: true,
		},
		{
			label: "header.account_items.Branches_Management",
			permission: [permission.academy, permission.playground],
			route: "/main/account/branches-management/overview",
			isAdmin: true,
		},
		{
			label: "header.account_items.my_booking",
			route: "/main/account/my-booking",
			permission: [
				// permission.academy,
				permission.player,
				permission.viwer,
				// permission.playground,
				permission.scouting,
			],
		},
		{
			label: "header.account_items.reservation_management",
			route: "/main/account/reservation-management",
			permission: [permission.academy, permission.playground],
		},
		{
			label: "header.account_items.images_videos_news",
			permission: [permission.academy, permission.playground],
			route: "/main/account/media-gallery/news-academy-playground",
			isAdmin: true,
		},
		{
			label: "header.account_items.player_list",
			permission: [permission.academy, permission.playground],
			route: "/main/account/players-list-tabs/review-list",
			isAdmin: true,
		},
		// {
		// 	label: "scouting_recruiting",
		// 	route: "/main/account/scouting-recruitment",
		// 	permission: [permission.academy, permission.scouting],
		// },
		{
			label: "header.account_items.programs_schedule",
			route: "/main/account/programs-schedule",
			permission: [permission.academy],
		},
		{
			label: "header.account_items.playground_schedule",
			route: "/main/account/playground-schedule",
			permission: [permission.playground],
		},
		{
			label: "header.account_items.news_media",
			route: "/main/account/news-media",
			permission: [permission.player],
		},
		// {
		// 	label: "reports_statistics",
		// 	route: "/main/account/reports-statistics",
		// 	permission: [permission.academy, permission.playground],
		// },
		{
			label: `registration_guide.${this.userType}`,
			permission: [
				permission.academy,
				permission.player,
				permission.playground,
			],
			route: "/main/account/registration-guid",
		},
		{
			label: `account.side_menu.terms_and_conditions`,
			permission: [
				permission.academy,
				permission.player,
				permission.playground,
				permission.viwer
			],
			route: "/main/account/terms-and-conditions",
		},
		{
			label: "header.account_items.logout",
			permission: [
				permission.academy,
				permission.player,
				permission.viwer,
				permission.playground,
				permission.scouting,
			],
			isAdmin: true,
			route: null,
		},
	];

	constructor(
		private LanguagesService: LanguagesService,
		@Inject(PLATFORM_ID) private platform: object
	) {
		this.translationcurr = LanguagesService.getSyncLanguageValue();
	}
	ngOnInit(): void {
		if (isPlatformBrowser(this.platform)) {
			this.accountManagementMenu = this.accountManagementMenu.filter(
				(ele) => {
					const filteredPermissions = ele.permission.includes(
						this.userType
					);
					const filteredAdminAccess = this._authService.isAdmin
						? ele.isAdmin
						: ele;

					return filteredPermissions && filteredAdminAccess;
				}
			);
			this.currentLanguage = this.LanguagesService.getSyncLanguageValue();
			this.langStyles = {
				"text-align": this.currentLanguage === "ar" ? "right" : "left",
				direction: this.currentLanguage === "ar" ? "rtl" : "ltr",
			};
			this.langAnchorStyles = {
				"margin-left": this.currentLanguage === "ar" ? "31px" : "0",
			};
		}
	}

	public menuToggle(): void {
		this.isMenuOpened = !this.isMenuOpened;
	}

	openNotificationMenu() {
		this.notificationOpened = !this.notificationOpened;
		this.accountMenuOpened = false;
	}

	openAccountMenu() {
		this.accountMenuOpened = !this.accountMenuOpened;
		this.notificationOpened = false;
	}

	closeMenus() {
		this.notificationOpened = false;
		this.accountMenuOpened = false;
	}

	handleAccountListClick(listItem: SideMenuItem): void {
		if (!listItem.disabled) {
			this.accountMenuOpened = !this.accountMenuOpened;
		}
		if (listItem.label === "header.account_items.logout") {
			this._authService.signOut().subscribe();
		}
	}
}
